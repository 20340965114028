$(document).ready(function() {
    // ���ض�������
    $("body").append("<div class='scroll' style='display: none; width: 50px; height: 50px'><a href='javascript:;'><img src='asset/img/ps1/waterfall_totop.png' alt=''></a></div>");
    showScroll();
    function showScroll() {
        $(window).scroll(function () {
            // ��������
            var scrollValue = $(window).scrollTop();
            scrollValue > 100 ? $('div[class=scroll]').fadeIn() : $('div[class=scroll]').fadeOut();
        });
        $('div[class=scroll]').click(function () {
            $("html,body").animate({ scrollTop: 0 }, 200);
        });
    }
})
